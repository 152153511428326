import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layouts/en'

// import Layout from '../components/layout'
import SEO from '../components/seo'
import Header from '../components/header'
import Specialization from '../components/specialization'
import Team from '../components/team'
import Responsibility from '../components/responsibility'
import Contacts from '../components/contacts'
import Footer from '../components/footer'

const IndexPage = (props) => (
  <Layout>
    <SEO
      title="SK+ Siekierzynski Kochlewski"
      description="We specialize in protecting intellectual property rights, which in a modern economy are essential for many businesses."
      lang="en" />
    <Header slides={props.data.advantages_tabs} locale={'en'} />
    <Specialization data={props.data.specialization} locale={'en'} />
    <Team
      data={props.data.team}
      persons={props.data.team_persons}
      locale={'en'}
    />
    <Responsibility
      data={props.data.responsibility}
      slides={props.data.responsibility_slides}
      locale={'en'}
    />
    <Contacts data={props.data.contacts} />
    <Footer />
  </Layout>
)

export const query = graphql`
  query {
    advantages_tabs: allMarkdownRemark(
      filter: {
        frontmatter: {
          section: { eq: "advantages_tabs" }
          locale: { eq: "en" }
        }
      }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          frontmatter {
            locale
            order
            title
            scaling_factor
            image {
              childImageSharp {
                fluid(maxWidth: 1386, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    specialization: markdownRemark(
      frontmatter: { section: { eq: "specialization" }, locale: { eq: "en" } }
    ) {
      html
      frontmatter {
        locale
        section_name
        title
        sub_title
        sub_desc
      }
    }
    team: markdownRemark(
      frontmatter: { section: { eq: "team" }, locale: { eq: "en" } }
    ) {
      frontmatter {
        locale
        section_name
        title
        sub_title
        title_other
      }
    }
    team_persons: allMarkdownRemark(
      filter: {
        frontmatter: { section: { eq: "team_persons" }, locale: { eq: "en" } }
      }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            locale
            order
            name
            first_name
            last_name
            status
            email
            image {
              childImageSharp {
                fluid(maxWidth: 320, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    responsibility: markdownRemark(
      frontmatter: { section: { eq: "responsibility" }, locale: { eq: "en" } }
    ) {
      html
      frontmatter {
        locale
        section_name
        title
        sub_title
      }
    }
    responsibility_slides: allMarkdownRemark(
      filter: {
        frontmatter: {
          section: { eq: "responsibility_slides" }
          locale: { eq: "en" }
        }
      }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            locale
            order
            name
            caption
            link_desc
            button_link
            image {
              childImageSharp {
                fluid(maxWidth: 680, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            logo_image {
              childImageSharp {
                fluid(maxWidth: 280, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    contacts: markdownRemark(
      frontmatter: { section: { eq: "contacts" }, locale: { eq: "en" } }
    ) {
      frontmatter {
        locale
        section_name
        title
        location_title
        location
        phones_title
        phones
        map_title
        map_link
        email_title
        email
        extra_link
        extra_link_title
      }
    }
  }
`

export default IndexPage
