const locale = {
  LOCALE: 'en',

  LANG_PL: 'PL',
  LANG_EN: 'ENG',
  LANG_DE: 'DE',

  NAVBAR_MENU_SPECIALIZATION: 'Specializations',
  NAVBAR_MENU_TEAM: 'Team',
  NAVBAR_MENU_RESPNSIBILITY: 'CSR',
  NAVBAR_MENU_CONTACTS: 'Contact',
  NAVBAR_MENU_PART_OF: 'Member of',

  BUTTON_MORE: 'MORE',
  BUTTON_HIDE: 'Hide',

  BUTTON_CHECK: 'CHECK',

  BUTTON_MAP_PART_ONE: 'FIND US',
  BUTTON_MAP_PART_TWO: 'ON GOOGLE MAPS',
}

export default locale
